import { setFormObject } from '../apps/KpModule/actions'
import { getModelId, getLanguage } from '../apps/KpModule/selectors'
import { initializeModule as initializeModuleAction } from '../apps/KpModule/actions'
import _ from 'lodash'
import i18n from '../utils/i18nClient'
import {getEditDefaultData, getListFieldsById, getModule, getObjectMode} from '../apps/KpModule/selectors'
import { actionTypes as reduxFormActionTypes } from 'redux-form'

export const FIELD_CLIENT_EVENT = '@@module/FIELD_CLIENT_EVENT'
export const LOAD_DEFAULT_EDIT = '@@module/LOAD_DEFAULT_EDIT'
export const EXECUTE_DT_LOAD_SUBSCRIPTIONS = '@@module/EXECUTE_DT_LOAD_SUBSCRIPTIONS'
export const EXECUTE_LOAD_SUBSCRIPTIONS = '@@module/EXECUTE_LOAD_SUBSCRIPTIONS'
export const EXECUTE_AFTER_SAVE_SUBSCRIPTIONS = '@@module/EXECUTE_AFTER_SAVE_SUBSCRIPTIONS'
export const INITIALIZE_MODULE = '@@module/INITIALIZE_MODULE'

const defaultFieldProps = [
    'editable',
    'creationOnly',
    'default',
    'disabled',
    'writable',
    'disableExport',
    'disableSort',
    'disableColumnsSort',
    'disableFilter',
    'flexGrow',
    'uppercase',
    'flexShrink',
    'id',
    'path',
    'tKey',
    'tooltip',
    'type',
    'style',
    'width',
    'minWidth',
    'initiallyNotVisible',
    'hidden',
    'placeholder',
    'clearable',
    'wholePositiveNumbers',
    'defaultSortBy',
    'defaultSortDirection',
    'headerClassName',
    'fullWidth',
    'dynamic',
    'autoFetch',
    'removable',
    'required',
    'strongPassword',
    'positiveNumber',
    'positiveNumberStrict',
    'wholeNumber',
    'wholePositiveNumber',
    'wholePositiveNumberStrict',
    'conversionRateYear',
    'maxLength',
    'maxLength34',
    'maxLength30',
    'maxLength11',
    'lengthEquals8or11',
    'bankData',
    'emailAddress',
    'phoneNumber',
    'hideLabel',
    'parentColumn',
    'translateName',
    'readOnly',
    'userGroupSelectedColor',
    'validations',
    'label',
    'className',
    'notDraggable',
    'data'
]

const transformPicking = (field, rules = defaultFieldProps) => {
    return rules.reduce((acc, rule) => {
        const from = rule.from || rule
        const to = rule.to || rule
        const operation = rule.operation || function(element){return element}
        _.set(acc, to, operation(_.get(field, from)))

        return acc
    }, {})
}

const transformField = field => {
    switch (field.type) {
        case 'text':
            return transformPicking(field, [
                ...defaultFieldProps,
                'timer',
                'noSpaces',
                'translate'
            ])
        case 'textarea':
            return transformPicking(field, [...defaultFieldProps, 'dynamicHeight'])
        case 'checkbox':
        case 'readOnly':
        case 'boolean':
            return transformPicking(field, defaultFieldProps)
        case 'fileLink':
            return transformPicking(field, [
                ...defaultFieldProps,
                'noTimestamp'
            ])
        case 'image':
            return transformPicking(field, [
                ...defaultFieldProps,
                'singleImage',
                'noThumbnail'
            ])
        case 'datePicker':
        case 'monthPicker':
            return {
                ...transformPicking(field, [...defaultFieldProps, 'showTime', 'rules', 'startDate', 'endDate']),
                opened: false
            }
        case 'dateRange':
        case 'monthPickerRange':
            return {
                ...transformPicking(field, [...defaultFieldProps, 'rules']),
                startOpened: false,
                endOpened: false
            }
        case 'object':
        case 'objectArray':
            return transformPicking(field, [
                ...defaultFieldProps,
                'translate',
                'display'
            ])
        case 'objectFromList':
            return transformPicking(field, [
                ...defaultFieldProps,
                'display',
                'fieldWithList',
                'translate',
                { from: 'access.id', to: 'dataList' }
            ])
        case 'accordion':
            return transformPicking(field, [
                ...defaultFieldProps,
                'noRowsMessage',
                'maxRows',
                'listFields',
                'editFields',
                'newable',
                'sortable',
                'sortableTable'
            ])
        case 'dtObjects':
            return transformPicking(field, [
                ...defaultFieldProps,
                'sortable',
                'autoGrow',
                'maxRows',
                'listFields',
                'applyBoard',
                'parentHeader',
                'headerHeight',
                'headerClassName',
                'displayTooltip',
                'noRowsMessage'
            ])
        case 'styledTable':
            return transformPicking(field, [
                ...defaultFieldProps,
                'autoGrow',
                'maxRows',
                'listFields',
                'noRowsMessage'
            ])
        case 'readOnlyObject':
            return transformPicking(field, [...defaultFieldProps, 'display'])
        case 'comment2':
            return transformPicking(field, [
                ...defaultFieldProps,
                'textWidth',
                'noRowsMessage',
                'tKeyText',
                'tKeyUser',
                'tKeyDate',
                'validationMessage'
            ])
        case 'files2':
            return transformPicking(field, [...defaultFieldProps, 'noRowsMessage', 'displayEmpty'])
        case 'dropdownObject':
        case 'asyncDropdownObject':
        case 'dualBoxList':
        case 'tags':
        case 'toggle':
            return transformPicking(field, [
                ...defaultFieldProps,
                'fieldPath',
                'display',
                'translate',
                'nullable',
                'disabled',
                'filter',
                'sortList',
                'timer',
                { from: 'access.id', to: 'dataList' },
                {
                    from: 'filters',
                    to: 'filters',
                    operation : function(arr = []){return arr.map(f => f.path)}
                }
            ])
        default:
            return transformPicking(field, defaultFieldProps)
    }
}

const groupAndTransformFields = fields =>
    fields.reduce(
        (acc, field) => {
            if (field.type === 'accordion') {
                const accordionField = {
                    ...transformField(field),
                    listFields: field.listFields.map(field => field.id),
                    editFields: field.editFields.map(field => field.id)
                }

                const childrenGrouppedFields = groupAndTransformFields([
                    ...field.listFields,
                    ...field.editFields
                ])

                acc.allIds.push(accordionField.id)
                acc.allIds.push(...childrenGrouppedFields.allIds)
                Object.assign(
                    acc.byId,
                    { [accordionField.id]: accordionField },
                    childrenGrouppedFields.byId
                )
            } else if (field.type === 'dtObjects' || field.type === 'styledTable') {
                const dtField = {
                    ...transformField(field),
                    listFields: field.listFields.map(field => field.id)
                }

                const childrenGrouppedFields = groupAndTransformFields([
                    ...field.listFields
                ])

                acc.allIds.push(dtField.id)
                acc.allIds.push(...childrenGrouppedFields.allIds)
                Object.assign(
                    acc.byId,
                    { [dtField.id]: dtField },
                    childrenGrouppedFields.byId
                )
            } else {
                acc.allIds.push(field.id)
                acc.byId[field.id] = transformField(field)
            }
            return acc
        },
        { allIds: [], byId: {} }
    )

const filterFieldIdsWithList = fields => {
    const filteredFields = fields.filter(field => field.autoList)
    return fieldIdsWithList(filteredFields)
}

const fieldIdsWithList = fields =>
    _(fields)
        .flatMap(field => {
            switch (field.type) {
                case 'asyncDropdownObject':
                case 'dropdownObject':
                case 'dualBoxList':
                case 'toggle':
                case 'tags':
                    return {
                        id: field.access.id,
                        url: field.access.entity.url
                    }
                case 'accordion':
                    return fieldIdsWithList(field.editFields)
                default:
                    return
            }
        })
        .compact()
        .value()

const accordionFieldEnhancer = field => ({
    listFields: enhanceFields(field.viewMap.dt.fields),
    editFields: enhanceFields(field.viewMap.form.fields)
})

const dtFieldEnhancer = field => ({
    listFields: enhanceFields(field.fields)
})

const enhanceFields = fields =>
    fields.map(field => {
        const fieldEnhancers = []

        if (field.type === 'accordion') {
            fieldEnhancers.push(accordionFieldEnhancer(field))
        }

        if (field.type === 'dtObjects' || field.type === 'styledTable') {
            fieldEnhancers.push(dtFieldEnhancer(field))
        }

        return fieldEnhancers.reduce(
            (acc, enhancer) => ({
                ...acc,
                ...enhancer
            }),
            field
        )
    })

function handleInitializeModule({ setCurrentModule, params, next }) {
    const module = setCurrentModule(params.moduleId)

    const listFields = module.viewMap.dt
        ? enhanceFields(
        module.viewMap.dt.fields.filter(f => !f.hidden)
    ): []
    const editFields = module.viewMap.form
        ? enhanceFields(
              module.viewMap.form.fields
                  .filter(f => !f.hidden)
                  .map(
                      field =>
                          field.fields
                              ? {
                                    ...field,
                                    fields: field.fields.filter(f => !f.hidden)
                                }
                              : field
                  )
          )
        : []

    const filters = module.filters && module.filters.reduce((acc,filter) => {
        return filter.filters ? [...acc, ...filter.filters.map(filter => ({...filter, filter: true})), filter ] : acc
    }, [])

    const filterFields = enhanceFields(filters.filter(f => f.client))

    const customEditLists = _.get(module, 'viewMap.form.dataLists', []).map(
        id => {
            const access = _.find(module.accesses, { id })
            return {
                id: access.id,
                url: access.entity.url
            }
        }
    )

    const filteredEditList = filterFieldIdsWithList(editFields)
    const filteredFilterLists = filterFieldIdsWithList(filterFields)

    const editLists = fieldIdsWithList(editFields)
    const filterLists = fieldIdsWithList(filterFields)

    const lists = [...editLists, ...customEditLists, ...filterLists]

    const actionModule = {
        id: module.id,
        name: module.name,
        tKey: module.tKey,
        step: module.step,
        alerts: module.alerts,
        kp: module.kp,
        category: module.category,
        defaultPanel: module.defaultPanel,
        noLeftBoard: module.noLeftBoard,
        useSocketsOnGet: module.useSocketsOnGet,
        useSocketsOnFind: module.useSocketsOnFind,
        useSocketsOnSave: module.useSocketsOnSave,
        parentHeader: module.parentHeader,
        headerClassName: module.headerClassName,
        headerRowClassName: module.headerRowClassName,
        headerHeight: module.headerHeight,
        chartParams: module.chart,
        newable: module.newable,
        listable: module.listable,
        removable: module.removable,
        sortable: module.sortable,
        memorizeColumns: module.memorizeColumns,
        hideScroll: module.hideScroll,
        protectedExport: module.protectedExport,
        removableGroup: module.removableGroup,
        workflowActions: module.workflowActions,
        deletionConfirmationMessage: module.deletionConfirmationMessage,
        deletionConditions: module.deletionConditions,
        updatable: module.updatable,
        defaultSortBy: module.defaultSortBy,
        defaultSortDirection: module.defaultSortDirection,
        defaultFormButtons: module.defaultFormButtons,
        manualFilters: module.manualFilters,
        impExp: module.impExp,
        model: module.model_.id,
        accessUrl: module.entity.url,
        fields: groupAndTransformFields([
            ...listFields,
            ...editFields,
            ...filterFields
        ]),
        list: {
            fields: listFields.map(fields => fields.id),
            accessId: module.viewMap.dt && module.viewMap.dt.access.id,
            deleteAccessId: module.viewMap.dt && module.viewMap.dt.deleteAccess.id,
            listIcon: !!module.viewMap.dt
        },
        edit: {
            fields: editFields.map(fields => fields.id),
            dataLists: [...filteredEditList, ...customEditLists].map(
                list => list.id
            ),
            accessId: module.viewMap.form && module.viewMap.form.access.id,
            deleteAccessId:
                module.viewMap.form && module.viewMap.form.deleteAccess.id,
            formIcon: !!module.viewMap.form
        },
        chart: {
            accessId: module.viewMap.chart && module.viewMap.chart.access.id,
            chartIcon: !!module.viewMap.chart
        },
        filter: {
            fields: filterFields.map(fields => fields.id),
            dataLists: filteredFilterLists.map(list => list.id)
        },
        dataLists: {
            allIds: lists.map(list => list.id),
            byId: lists.reduce((acc, list) => {
                acc[list.id] = list
                return acc
            }, {})
        }
    }

    return next(
        initializeModuleAction(
            actionModule,
            params.groupModel,
            params.user,
            params.language
        )
    )
}

function handleFieldClientEvent({ getCurrentModule, params }) {
    const module = getCurrentModule()

    const field = _.get(module, 'viewMap.form.fields').find(
        field =>
            field.path === params.path ||
            (field.viewMap &&
                _.some(
                    field.viewMap.form.fields,
                    fld => fld.path === params.path
                ))
    )

    field && field[params.key] && field[params.key](params.payload)
}

function handleLoadDefaultEdit({ next, state }) {
    return next(setFormObject(getEditDefaultData(state), getModule(state), getObjectMode(state)))
}

function handleExecuteDtLoadSubscriptions({getCurrentModule, store, state }) {
    const module = getCurrentModule(state)
    const modelId = getModelId(state)
    const language = getLanguage(state)
    const t = i18n.getFixedT(
        language,
        modelId ? [modelId, "platform"] : "platform"
    );
    return module.viewMap.dt.onLoad({module, state, store, t})
}

function handleExecuteLoadSubscriptions({getCurrentModule, store, state }) {
    const module = getCurrentModule(state)
    const modelId = getModelId(state)
    const language = getLanguage(state)
    const t = i18n.getFixedT(
        language,
        modelId ? [modelId, "platform"] : "platform"
    );
    return module.viewMap.form.onOpen({module, state, store, t})
}

function handleAfterSaveSubscriptions({getCurrentModule, store, state }) {
    const module = getCurrentModule(state)
    const modelId = getModelId(state)
    const language = getLanguage(state)
    const t = i18n.getFixedT(
        language,
        modelId ? [modelId, "platform"] : "platform"
    );
    if(_.isFunction(module.viewMap.form.afterSave)) {
        return module.viewMap.form.afterSave({module, state, store, t})
    }
}

const middlewareHandlers = [
    [INITIALIZE_MODULE, handleInitializeModule],
    [FIELD_CLIENT_EVENT, handleFieldClientEvent],
    [LOAD_DEFAULT_EDIT, handleLoadDefaultEdit],
    [EXECUTE_DT_LOAD_SUBSCRIPTIONS, handleExecuteDtLoadSubscriptions],
    [EXECUTE_LOAD_SUBSCRIPTIONS, handleExecuteLoadSubscriptions],
    [EXECUTE_AFTER_SAVE_SUBSCRIPTIONS, handleAfterSaveSubscriptions]
]

export const moduleMiddleware = store => next => action => {
    const clientEnhancer = store.clientEnhancer

    let middlewareIntercepted = false
    let result

    const previousState = store.getState()
    const previousModule = clientEnhancer.getCurrentModule()

    middlewareHandlers.forEach(([actionType, handler]) => {
        const params = action[actionType]
        if (typeof params !== 'undefined') {
            middlewareIntercepted = actionType
            result = handler({
                setCurrentModule: id =>
                    clientEnhancer.setCurrentModule(id, store),
                getCurrentModule: () => clientEnhancer.getCurrentModule(),
                store,
                next,
                action,
                state: previousState,
                params
            })
        }
    })

    const previousModelId = getModelId(previousState)
    const previousLanguage = getLanguage(previousState)
    const previousT = i18n.getFixedT(
        previousLanguage,
        previousModelId ? [previousModelId, "platform"] : "platform"
    );

    let finalAction = action

    if (!middlewareIntercepted) {
        // here we add some meta information to be used in the standard reducers
        // typically we add here data that can't be obtained in a slice reducer

        finalAction = {
            ...action,
            meta: {
                ...(action.meta || {}),
                listFieldsById: getListFieldsById(previousState || {}),
                module: clientEnhancer.getCurrentModule(),
                t: previousT
            }
        }

        result = next(finalAction)
    }

    const state = store.getState()
    const module = clientEnhancer.getCurrentModule()

    const modelId = getModelId(state)
    const language = getLanguage(state)

    const t = i18n.getFixedT(
        language,
        modelId ? [modelId, "platform"] : "platform"
    );

    if (module && previousModule === module) {

        module.stateSubscriptions.forEach(({ subscription, statePath }) => {
            const previousValue = _.get(previousState, statePath)
            const newValue = _.get(state, statePath)
            if (previousValue !== newValue) {
                subscription(newValue, previousValue, {
                    formInitialize: finalAction.type === reduxFormActionTypes.INITIALIZE,
                    getObjectSuccessAction: finalAction.type === 'GET_OBJECT_SUCCESS',
                    formDestroy: finalAction.type === reduxFormActionTypes.DESTROY,
                    module,
                    store,
                    t,
                    action: finalAction
                })
            }
        })

        module.actionSubscriptions.forEach(({ subscription, actionType }) => {
            if (actionType === action.type) {
                subscription({ module, store, action: finalAction, t })
            }
        })
    }

    return result
}

export default moduleMiddleware
