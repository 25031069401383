import React, { Component } from 'react'
import _ from 'lodash'
import { AutoSizer, BooleanCell, InputCell, Table, FailureIndicator } from './DataTable'
import { defaultTableHeaderRowRenderer } from 'react-virtualized';
import DatePicker from '../components/Calendar/DatePicker'
import {
    defaultRowRenderer,
    Column, defaultHeaderRenderer
} from 'react-virtualized/dist/commonjs/Table'
import ValidationUI from './ValidationUI'
import {makeDataGetter, getEditStartDateById, getEditEndDateById} from '../apps/KpModule/selectors'
import './DtObjectsComponent.css'
import DownloadDataTable from './DataTable/DownloadDataTable'
import DataTableFilter from './DataTable/DataTableFilter'
import { applyFilter, applySort } from './DataTable/DataTable'
import ColumnSelector from './DataTable/ColumnSelector'
import HoverPopover from "./HoverPopover";
import {setFieldWidth, toggleCalendar} from '../apps/KpModule/actions'
import {connect} from "react-redux";
import {HEADER_HEIGHT, ROW_HEIGHT} from "./DataTable/Table";
import moment from "moment";
import Draggable from "react-draggable";
import history from "../history";
import CheckboxField from "./Form/CheckboxField";

const tableHeightForRows = nRows => ROW_HEIGHT * nRows


const getFormattedAmount = (amount, language) => {
    const parsed = parseFloat(amount)
    if(parsed) return new Intl.NumberFormat(language, {minimumFractionDigits: 2}).format(parsed)
}


const DATE_FORMAT = 'YYYY-MM-DD'

function dateComparator(startDate, endDate) {
    if (!startDate && !startDate) return

    const referenceStartDate = startDate && moment(startDate, DATE_FORMAT).startOf('day')
    const referenceEndDate = endDate && moment(endDate, DATE_FORMAT).startOf('day')

    return function compareDate(date) {
        return date
            ? startDate && endDate
                ? moment(referenceStartDate).diff(date.startOf('day'), 'days')  > 0 || moment(referenceEndDate).diff(date.startOf('day'), 'days')  < 0
                : startDate
                    ? moment(referenceStartDate).diff(date.startOf('day'), 'days')  > 0
                    : moment(referenceEndDate).diff(date.startOf('day'), 'days')  < 0
            : false
    }
}


class DtObjectsComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            sortBy: null,
            sortDirection: 'ASC',
            filter: '',
            columnSelectorOpened: false,
            selectorOptions: props.listFields
                .map(column => ({
                    key: column.path,
                    label: column.tKey,
                    selected: true
                })),
            openById: {},
            startDateById: {},
            endDateById: {}
        }
        this.cellRenderer = this.cellRenderer.bind(this)
        this.headerRenderer = this.headerRenderer.bind(this)
        this.renderColumnHeader = this.renderColumnHeader.bind(this)
    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
        if(prevProps.listFields !== this.props.listFields) {
            return this.props.listFields
        }
        return null
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (snapshot){
            this.setState(
                {
                    selectorOptions: snapshot
                        .map(column => ({
                            key: column.path,
                            label: column.tKey,
                            selected: true
                        }))
                }
            )
        }

    }

    cellRenderer(sortedObjects, field, onChange, parentFieldDisabled) {
        const {startDateById, endDateById, groupModel, language, setFilterMemory} = this.props
        switch (field.type) {
            case 'checkbox':
                return ({ rowIndex, rowData, cellData, dataKey }) => (
                    <input
                        type="checkbox"
                        value={cellData}
                        checked={cellData}
                        disabled={parentFieldDisabled || field.disabled}
                        onChange={() => {
                            const newRowData = {
                                ...rowData,
                                [dataKey]: !cellData
                            }
                            onChange({ rowIndex, rowData: newRowData })
                        }}
                    />
                )
            case 'datePicker':
                return ({ rowIndex, rowData, cellData, dataKey }) => {
                    const startDate = startDateById[`${field.id}-${rowData.id}`]
                    const endDate = endDateById[`${field.id}-${rowData.id}`]
                    const comparator = dateComparator(startDate, endDate)
                    return (
                        <DatePicker
                            value={cellData}
                            style={field.style}
                            open={this.state.openById[`${field.id}-${rowData.id}`]}
                            disabled={parentFieldDisabled || field.disabled}
                            onOpenChange={() => this.setState({
                                openById: {
                                    ...this.state.openById,
                                    [`${field.id}-${rowData.id}`] : !this.state.openById[`${field.id}-${rowData.id}`]
                                }
                            })}
                            onChange={value => {
                                const newRowData = {
                                    ...rowData,
                                    [dataKey]: value
                                }
                                onChange({ rowIndex, rowData: newRowData })
                            }}
                            disabledDate={comparator}
                            dtObject={true}
                        />
                    )
                }
            case 'editText':
                if(parentFieldDisabled || field.disabled) return ({ rowData, dataKey }) => <div>{_.get(rowData, dataKey)}</div>
                return ({ rowIndex, rowData, dataKey }) => {
                    if(!!rowData._disabled) return <div>{_.get(rowData, dataKey)}</div>
                    const cellData = _.get(rowData, dataKey)

                    const shallowPath = dataKey.split('.')[0]
                    const deepPath = dataKey.split('.')[1]

                    return (
                        <InputCell
                            value={cellData}
                            onPaste={ event => {
                                if(deepPath) {
                                    event.preventDefault()

                                    const paste = (event.clipboardData || window.clipboardData).getData('text');
                                    const result = paste.split('\n');
                                    const result2 = []
                                    result.forEach(row => result2.push(row.split('\t')))

                                    const changes = {}
                                    let currentRowIndex = rowIndex
                                    result2.forEach(pastedRow => {
                                        const currentRow = sortedObjects[currentRowIndex]
                                        if(!currentRow) return
                                        if(!deepPath) {
                                            changes[currentRowIndex] = {
                                                ...currentRow,
                                                [field.path]: {
                                                    ...currentRow[field.path],
                                                    data: pastedRow[0]
                                                }
                                            }
                                        } else {
                                            const dynamicFieldKeys = Object.keys(currentRow[shallowPath])

                                            let currentCellIndex = dynamicFieldKeys.indexOf(deepPath)
                                            const newDynamicFieldData = pastedRow.reduce((acc, pastedCell) => {
                                                if(!dynamicFieldKeys[currentCellIndex]) return acc
                                                const object = {
                                                    ...acc,
                                                    [dynamicFieldKeys[currentCellIndex]]: pastedCell
                                                }
                                                currentCellIndex++
                                                return object
                                            }, {})
                                            changes[currentRowIndex] = {
                                                ...currentRow,
                                                [shallowPath]: {
                                                    ...currentRow[shallowPath],
                                                    ...newDynamicFieldData
                                                }
                                            }
                                        }
                                        currentRowIndex++
                                    })
                                    onChange(changes, true)
                                }

                            }}
                            onChange={event => {

                                const newRowData = deepPath
                                    ? {
                                        ...rowData,
                                        [shallowPath]: {
                                            ...rowData[shallowPath],
                                            [deepPath]: event.target.value
                                        }
                                    }
                                    : {
                                        ...rowData,
                                        [shallowPath]: event.target.value
                                    }
                                onChange({ rowIndex, rowData: newRowData })
                            }}
                        />
                    )
                }
            case 'boolean':
                return ({ cellData }) => <BooleanCell value={cellData} />
            case 'failureIndicator':
                return ({ cellData }) => <FailureIndicator value={cellData} />
            case 'links':
                return ({ rowData, dataKey }) => {
                    const cellData = _.get(rowData, dataKey)
                    return (
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            {cellData.map((link, index) => <div style={{display: 'flex', flexDirection: 'row'}}>
                                {index !== 0 && <div className="DtObjectsComponent-Link-separator"> {" | "}</div>}
                                <div
                                    className="DtObjectsComponent-Link"
                                    onClick={() => {
                                        if(link.query) {
                                            setFilterMemory(link.query)
                                            history.push(`/business/${groupModel.id}${link.path}`)
                                        }
                                    }}
                                >
                                    {link.name}
                                </div>
                            </div>)}
                        </div>
                    )
                }
            case 'customized':
                return ({ rowIndex, rowData, dataKey }) => {
                    const cellData = _.get(rowData, dataKey)
                    switch (cellData.type) {
                        case 'checkbox':
                            return (
                                <CheckboxField
                                    disabled={parentFieldDisabled || field.disabled || !!cellData.disabled}
                                    value={cellData.data}
                                    onChange={() => {
                                        const newRowData = {
                                            ...rowData,
                                            [dataKey]: {
                                                ...cellData,
                                                data: !cellData.data
                                            }
                                        }
                                        onChange({ rowIndex, rowData: newRowData })
                                    }}
                                />
                            )
                        case 'datePicker':
                            return (
                                <DatePicker
                                    value={cellData.data}
                                    style={field.style}
                                    open={this.state.openById[`${field.id}-${rowData.id}`]}
                                    disabled={parentFieldDisabled || field.disabled}
                                    onOpenChange={() => this.setState({
                                        openById: {
                                            ...this.state.openById,
                                            [`${field.id}-${rowData.id}`] : !this.state.openById[`${field.id}-${rowData.id}`]
                                        }
                                    })}
                                    onChange={value => {
                                        const newRowData = {
                                            ...rowData,
                                            [dataKey]: {
                                                ...cellData,
                                                data: value
                                            }
                                        }
                                        onChange({ rowIndex, rowData: newRowData })
                                    }}
                                    dtObject={true}
                                />
                            )
                        case 'editText':
                            const shallowPath = field.path.split('.')[0]
                            const deepPath = field.path.split('.')[1]
                            if(parentFieldDisabled || field.disabled) return <div style={cellData.style}>{cellData.data}</div>
                            return (
                                <InputCell
                                    style={cellData.style}
                                    value={cellData.data}
                                    onPaste={ event => {
                                        if(deepPath) {
                                            event.preventDefault()

                                            const paste = (event.clipboardData || window.clipboardData).getData('text');
                                            const result = paste.split('\n');
                                            const result2 = []
                                            result.forEach(row => result2.push(row.split('\t')))

                                            const changes = {}
                                            let currentRowIndex = rowIndex
                                            result2.forEach(pastedRow => {
                                                const currentRow = sortedObjects[currentRowIndex]
                                                if(!currentRow) return
                                                if(!deepPath) {
                                                    const currentCell = currentRow[field.path]
                                                    if(currentCell.type || currentCell.type === 'editText') {
                                                        changes[currentRowIndex] = {
                                                            ...currentRow,
                                                            [field.path]: {
                                                                ...currentCell,
                                                                data: pastedRow[0]
                                                            }
                                                        }
                                                    }
                                                } else {
                                                    const dynamicFieldKeys = Object.keys(currentRow[shallowPath])

                                                    let currentCellIndex = dynamicFieldKeys.indexOf(deepPath)
                                                    const newDynamicFieldData = pastedRow.reduce((acc, pastedCell) => {
                                                        if(!dynamicFieldKeys[currentCellIndex]) return acc
                                                        const currentCell = currentRow[shallowPath][dynamicFieldKeys[currentCellIndex]]
                                                        if(!currentCell.type || currentCell.type !== 'editText') return acc
                                                        const object = {
                                                            ...acc,
                                                            [dynamicFieldKeys[currentCellIndex]]: {
                                                                ...currentCell,
                                                                data: pastedCell
                                                            }
                                                        }
                                                        currentCellIndex++
                                                        return object
                                                    }, {})
                                                    changes[currentRowIndex] = {
                                                        ...currentRow,
                                                        [shallowPath]: {
                                                            ...currentRow[shallowPath],
                                                            ...newDynamicFieldData
                                                        }
                                                    }
                                                }
                                                currentRowIndex++
                                            })
                                            onChange(changes, true)
                                        }

                                    }}
                                    onChange={event => {
                                        const newRowData = deepPath
                                            ? {
                                                ...rowData,
                                                [shallowPath]: {
                                                    ...rowData[shallowPath],
                                                    [deepPath]: {
                                                        ...cellData,
                                                        data: event.target.value
                                                    }
                                                }
                                            }
                                            : {
                                                ...rowData,
                                                [shallowPath]: {
                                                    ...rowData[shallowPath],
                                                    data: event.target.value
                                                }
                                            }

                                        onChange({ rowIndex, rowData: newRowData })
                                    }}
                                />
                            )
                        case 'boolean':
                            return <BooleanCell value={cellData.data} />
                        /*
                        case 'boolean':
                            return ({ cellData }) => (
                                <div>{cellData.data ? 'TRUE': 'FALSE'}</div>
                            )
                         */
                        case 'amount':
                            return <div style={{textAlign: 'end'}}>{getFormattedAmount(cellData.data, language)}</div>
                        default:
                            return cellData && _.has(cellData, 'data') ? (
                                <div style={cellData.style}>
                                    {cellData.data?.toString()}
                                </div>
                            ) : (
                                <div>{cellData?.toString()}</div>
                            )
                    }
                }
            default:
                return ({ cellData }) => <div>{cellData}</div>
        }
    }

    changeFilter(filter) {
        this.setState({filter})
    }

    handleOpenColumnSelector = ({ opened }) => {
        this.setState({
            columnSelectorOpened: opened
        })
    }

    handleChangeColumnSelection({ key, selected }) {
        const selectorOptions = this.state.selectorOptions.map(option => {
            return option.key === key ? { ...option, selected } : option
        })

        this.setState({selectorOptions})
    }

    headerRenderer(props, selectedColumns, parentHeader) {
        const {style} = props
        const groupedByParent = _.groupBy(selectedColumns, 'parentColumn')
        const parentColumns = Object.keys(groupedByParent).map(key => {
            return groupedByParent[key].reduce((acc, column) => {
                acc.width += column.width
                acc.nb++
                return acc
            }, { path: key, width: 0, nb: 0 })
        })
        return parentHeader
            ? (
                <div>
                    <div className='DataTable-Table-parent-headerRow' style={{...style, borderBottom: '1px solid #adb0ba'}}>
                        {parentColumns.map((column, index) => <div style={{
                            flex: `${column.nb} ${column.width}px`,
                            fontWeight: 'bold',
                            fontSize: '14px',
                            marginTop: '10px',
                            marginRight: `${10 * column.nb}px`,
                            textTransform: 'none',
                            outline: 'none',
                            marginLeft: index ? '0' : '10px',
                        }}>
                            {column.path}
                        </div>)}
                    </div>
                    {defaultTableHeaderRowRenderer(props)}
                </div>
            )
            : defaultTableHeaderRowRenderer(props)

    }

    renderColumnHeader = (column, nextColumn) => {
        const {objects = [], setFieldWidth, t} = this.props

        return headerParams => {
            const getNumber = o => {
                const numberCandidate = Number(_.get(o, column.path))
                return _.isNaN(numberCandidate) ? 0 : numberCandidate
            }
            const total = _.round(
                objects.reduce((acc, o) => getNumber(o) + acc, 0)
            )

            const headerRenderer = defaultHeaderRenderer(headerParams)

            let children = []

            if (column.tooltip) {
                children.push(
                    <HoverPopover
                        key="overlay"
                        id={'KpDataTable-tooltip'}
                        message={`${t('total')} : ${total}`}
                        placement={'top'}
                    >
                        {headerRenderer[0]}
                    </HoverPopover>
                )
            } else {
                children.push(headerRenderer[0])
            }


            // the second element is the sort indicator
            if (headerRenderer[1]) {
                children.push(headerRenderer[1])
            }

            if(nextColumn) {
                children.push(
                    <div
                        onClick={event => event.stopPropagation()}
                    >
                        <Draggable
                            axis="x"
                            defaultClassName="DragHandle"
                            defaultClassNameDragging="DragHandleActive"
                            onDrag={(event, data) => {
                                const delta = data.deltaX;
                                const columnWidth = column.width + delta
                                const nextColumnWidth = nextColumn.width - delta

                                setFieldWidth(column.id, columnWidth)
                                setFieldWidth(nextColumn.id, nextColumnWidth)
                            }}
                            position={{ x: 0 }}
                            zIndex={999}
                        >
                            <span className="DragHandleIcon">⋮</span>
                        </Draggable>
                    </div>
                )
            }
            return children
        }
    }

    computeHeight = objects => {
        const {field: {maxRows, autoGrow, disableHeader, headerHeight}} = this.props

        const finalHeaderHeight = disableHeader
            ? 0
            : headerHeight || HEADER_HEIGHT

        const totalHeight = tableHeightForRows(objects.length)
        if (autoGrow) return totalHeight + finalHeaderHeight

        const maxHeight = maxRows && tableHeightForRows(maxRows)

        const height = maxRows ? Math.min(totalHeight, maxHeight) : totalHeight

        // the max is to ensure we see at least one line to show the no row message
        return Math.max(
            height + finalHeaderHeight,
            HEADER_HEIGHT + ROW_HEIGHT
        )
    }

    getHeight = objects => this.props.height || this.computeHeight(objects)


    render() {
        const {
            input: { value, onChange },
            meta:{ touched, error },
            field,
            listFields,
            module,
            t,
            language,
            touch,
            path,
            required,
            disabled,
            displayTooltip,
            exportTableObject
        } = this.props
        const translatedError = t(error)

        const onRowChange = (newValue, multiple) => {
            touch(field.path)

            const filteredObjects = applyFilter({
                objects: value,
                filter: this.state.filter,
                columns: enhancedColumns
            })

            const sortedObjects = applySort({
                objects: filteredObjects,
                sortBy: this.state.sortBy,
                sortDirection: this.state.sortDirection,
                columns: enhancedColumns
            })

            if(multiple) {
                onChange(
                    sortedObjects.map( (data, index) => {
                        return newValue[index] ? newValue[index] : data
                    })
                )
            } else {
                const { rowIndex, rowData } = newValue
                onChange(
                    sortedObjects.map( (data, index) => {
                        return rowIndex === index ? rowData : data
                    })
                )
            }
        }

        const cellDataGetterForField = makeDataGetter({ language, t })

        const visibleMap = this.state.selectorOptions.reduce(
            (acc, column) => Object.assign(acc, { [column.key]: column.selected }),
            {}
        )

        const selectedColumns = listFields.filter(
            field => visibleMap[field.path]
        )

        if(field.parentHeader) {
            selectedColumns.sort((a, b) => {
                if(!a.parentColumn) a.parentColumn = ""
                if(!b.parentColumn) b.parentColumn = ""
                // Group by parentColumn
                if (a.parentColumn < b.parentColumn) return -1
                if (a.parentColumn > b.parentColumn) return 1

                // If parentColumn is the same, sort by another attribute, for example, id
                if (a.order < b.order) return -1
                if (a.order > b.order) return 1

                return 0
            })
        }

        const enhancedColumns = selectedColumns.map((field, index) => {
            const nextColumn = selectedColumns[index + 1]
            return {
                ...field,
                dataKey: field.path,
                label: t(field.tKey),
                dataGetter: cellDataGetterForField(field),
                headerRenderer: this.renderColumnHeader(field, nextColumn)
            }
        })

        const filteredObjects = applyFilter({
            objects: value,
            filter: this.state.filter,
            columns: enhancedColumns
        })

        const sortedObjects = field.sortable
            ? applySort({
                objects: filteredObjects,
                sortBy: this.state.sortBy,
                sortDirection: this.state.sortDirection,
                columns: enhancedColumns
            })
            : filteredObjects


        const rowRenderer = (object) => {

            const displayRow = defaultRowRenderer({...object, style: {...object.style, ...object.rowData.style}})


            const values = selectedColumns.map(
                column => {
                    return <div>
                        <div>{t(column.tKey)}</div>
                        <strong>{object.rowData[column.path]}</strong>
                        <br/>
                        <br/>
                    </div>
                }
            )

            return displayTooltip
                ? (
                    <HoverPopover
                        key={object.rowData.id}
                        id={'comment-popover'}
                        message={
                            <div>
                                {values}
                            </div>
                        }
                        placement={'top'}
                    >
                        {displayRow}
                    </HoverPopover>
                )
                : displayRow
        }

        return (
            <div style={{padding: '5px'}}>
                <label className="DtObjectsComponent-label" >{t(path)} {required && '*'}</label>
                <div style={{border: field.applyBoard ? '1px solid #ddd' : 'none', borderRadius: field.applyBoard ? '4px': ''}}>
                    {
                        field.applyBoard && (
                            <div className="DtObjectsComponent-Board">
                                <div className="DtObjectsComponent-Board-left">
                                    <ColumnSelector
                                        options={this.state.selectorOptions}
                                        opened={this.state.columnSelectorOpened}
                                        open={this.handleOpenColumnSelector.bind(this)}
                                        changeColumnSelection={this.handleChangeColumnSelection.bind(this)}
                                        t={t}
                                    />
                                    <DownloadDataTable
                                        objects={sortedObjects}
                                        columns={enhancedColumns}
                                        filename={t(module.tKey)}
                                        exportTableObject={exportTableObject}
                                    />
                                </div>
                                <div className="DtObjectsComponent-Board-right">
                                    <DataTableFilter
                                        value={this.state.filter}
                                        onChange={this.changeFilter.bind(this)}
                                        placeholder={`${t('search')}...`}
                                        //tooltip={this.props.searchTooltip}
                                    />
                                </div>
                            </div>
                        )
                    }
                    <div className={field.applyBoard ? "DtObjectsComponent-container-withBoard" : "DtObjectsComponent-container"}>
                        <AutoSizer disableHeight>
                            {({ width }) => (
                                <Table
                                    objects={sortedObjects}
                                    gridClassName={'DtObjectsComponent-innerGrid'}
                                    headerHeight={field.headerHeight}
                                    disableHeader={field.disableHeader}
                                    headerClassName={field.headerClassName}
                                    headerRowRenderer={props => this.headerRenderer(props, selectedColumns, field.parentHeader)}
                                    width={width}
                                    height={this.getHeight(sortedObjects)}
                                    autoGrow={field.autoGrow}
                                    maxRows={field.maxRows}
                                    noHover={field.noHover}
                                    rowRenderer={rowRenderer}
                                    sort={({ sortBy, sortDirection }) => {
                                        if(field.sortable) {
                                            this.setState({
                                                sortBy,
                                                sortDirection
                                            })
                                        }
                                    }}
                                    sortBy={this.state.sortBy}
                                    sortDirection={this.state.sortDirection}
                                >
                                    {enhancedColumns.map(listField => {

                                        return (
                                            <Column
                                                {...listField}
                                                key={listField.id}
                                                flexGrow={1}
                                                width={listField.width || 200}
                                                dataKey={listField.path}
                                                label={t(listField.tKey)}
                                                cellDataGetter={({ rowData }) => cellDataGetterForField(listField)(rowData)}
                                                cellRenderer={this.cellRenderer(sortedObjects, listField, onRowChange, disabled)}
                                                className={listField.className}
                                                style={listField.style}
                                            />
                                        )
                                    })}
                                </Table>
                            )}
                        </AutoSizer>
                    </div>
                    {touched && error && <ValidationUI error={translatedError}/>}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    startDateById: getEditStartDateById(state),
    endDateById: getEditEndDateById(state),
})

const mapDispatchToProps = {
    toggleCalendar,
    setFieldWidth,
}

export default connect(mapStateToProps, mapDispatchToProps)(DtObjectsComponent)
