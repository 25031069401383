import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {EyeOff, Eye} from 'react-feather'
import logoKP from '../img/keenpoint-logo-horizontal.png'
import logoCNDA from '../img/logo_cnda.svg'
/*
import logoRTE from '../img/logo_RTE.png'
import logoHermes from '../img/logo_Hermes.png'
import logoEDF from '../img/logo_EDF.png'
import logoCELIO from '../img/logo_CELIO.png'
import logoUAPL from '../img/logo_uapl.png'
import logoCSE from '../img/logo_mycsemanco.png'
 */
import './login.css'
import DynamicStyledInput from "./Form/DynamicStyledInput";
import googleButtonLogo from '../img/google_button_logo.png'
import oktaButtonLogo from '../img/okta_button_logo.png'

const getButtonLogo = strategy => {
    switch (strategy) {
        case 'google':
            return googleButtonLogo
        case 'okta':
            return oktaButtonLogo
        default:
            return null
    }
}

const getButtonStyle = strategy => {
    switch (strategy) {
        case 'google':
            return {
                height: '17px',
                width: '17px',
                marginRight: '10px',
                marginBottom: '3px'
            }
        case 'okta':
            return {
                height: '21px',
                width: '21px',
                marginRight: '10px',
                marginBottom: '3px'
            }
        default:
            return {
                height: '22px',
                marginRight: '10px',
                marginBottom: '3px'
            }
    }
}

const getLogo = (client) => {
    switch (client) {
        case 'slp-celio':
        case 'edfin':
        case 'uapl':
        case 'cse':
        case 'rte':
        case 'thom':
        case 'hermes':
        case 'preprod':
            return logoKP
        case 'cnda':
            return logoCNDA
        default:
            return
    }
}

const getLogoStyle = (client) => {
    switch (client) {
        case 'slp-celio':
        case 'edfin':
        case 'uapl':
        case 'cse':
        case 'thom':
        case 'rte':
        case 'hermes':
            return {width: '200px'}
        case 'cnda':
            return {height: '200px'}
        default:
            return {width: '200px'}
    }
}

class Login extends Component {
    constructor() {
        super()

        this.state = {
            username: '',
            password: '',
            showPassword: false
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleChange(field, event) {
        const newValue = event.target.value
        this.setState({
            [field]: newValue
        })
    }

    handleSubmit(event) {
        event.preventDefault()

        const { handleSubmit } = this.props
        handleSubmit && handleSubmit(this.state)
    }

    render() {
        const {t, serverUrl, authStrategies, client} = this.props
        const isLocalUsed = authStrategies && authStrategies.some(strategy => strategy === 'local')
        return (
            <div className="Login-container">
                <div className="Login-form-container">
                    <div className="Login-logo-container">
                        <img
                            alt="Login-logo"
                            src={getLogo(client)}
                            style={getLogoStyle(client)}
                        />
                    </div>
                    <div className= "Login-well">
                        <h1 className="Login-form-title">
                            {t('welcomeBackMessage')}
                        </h1>
                        <h2 className="Login-form-subtitle">
                            {t('signInToContinue')}
                        </h2>
                        <form onSubmit={this.handleSubmit}>
                            {
                                isLocalUsed && (
                                    <div>
                                        <DynamicStyledInput
                                            id="LoginForm-username"
                                            path="username"
                                            type="text"
                                            value={this.state.username}
                                            onChange={event => this.handleChange("username", event)}
                                            t={t}
                                        />
                                        <br />
                                        <div style={{position: 'relative'}}>
                                            <DynamicStyledInput
                                                id="LoginForm-password"
                                                path="password"
                                                type={ this.state.showPassword ? "text" : "password"}
                                                value={this.state.password}
                                                onChange={event => this.handleChange("password", event)}
                                                t={t}
                                            />
                                            <span onClick={() => this.setState({showPassword: !this.state.showPassword})} className="Login-eye-icon">
                                                {
                                                    this.state.showPassword
                                                        ? <EyeOff size={17} />
                                                        : <Eye size={17} />
                                                }
                                            </span>
                                        </div>
                                        <br />
                                        <div className='Login-buttons'>
                                            <button type="submit" className="btn Login-mainButton remove-button-outline" value="submit">
                                                {t('login')}
                                            </button>
                                            <a className="Login_passwordLink" onClick={() => this.props.history.push('/forgotten')} >{t('updatePassword')}</a>
                                        </div>
                                    </div>
                                )

                            }
                            {
                                authStrategies && authStrategies.length !== 1 && <hr/>
                            }
                            {
                                authStrategies && authStrategies.filter(strategy => strategy !== 'local').map((strategy, index) => (
                                    <div key={index}>
                                        <div className='Login-buttons'>
                                            <button type="button" className="btn remove-button-outline" onClick={() => window.open(`${serverUrl}/auth/${strategy}`, "_self")} style={{background: 'white', border: '1px solid #cccccc'}} >
                                                {
                                                    getButtonLogo(strategy) && <img
                                                        id={`${strategy}_logo`}
                                                        alt={`${strategy}`}
                                                        src={getButtonLogo(strategy)}
                                                        style={getButtonStyle(strategy)}
                                                    />
                                                }
                                                <span style={{fontSize: 'medium'}}>{t(`${strategy}Login`)}</span>
                                            </button>
                                        </div>
                                    </div>
                                ))
                            }
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

Login.propTypes = {
    handleSubmit: PropTypes.func.isRequired
}

export default Login

