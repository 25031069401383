export const entity = {
    name: 'Bank',
    fields: [
        'bank',
        {path: 'bic', encrypted: true},
        {path: 'iban', unique: true, encrypted: true},
        {path: 'temporaryAccount'},
        {path: 'provisionalAccount', unique: true},
        {path: 'definitiveAccount', unique: true},
        {path: 'active', type: 'boolean'}
    ]
}

export const module_ = {
    object: 'Bank',
    tKey: 'mTitle_bank',
    objectIdentifier: 'bank',
    category: {
        path: 'configuration',
        icon: 'settings'
    },
    viewMap: {
        dt: [
            {path: 'bank'},
            {path: 'iban', initiallyNotVisible: true},
            {path: 'bic', initiallyNotVisible: true},
            {path: 'temporaryAccount', initiallyNotVisible: true},
            {path: 'provisionalAccount', initiallyNotVisible: true},
            {path: 'definitiveAccount', initiallyNotVisible: true},
            {path: 'active'}
        ],
        form: [
            {path: 'bank', required: true},
            {path: 'iban', required: true, noSpaces: true, maxLength34: true, bankData: true},
            {path: 'bic', required: true, noSpaces: true, lengthEquals8or11: true, bankData: true},
            {path: 'temporaryAccount', required: true},
            {path: 'provisionalAccount', required: true},
            {path: 'definitiveAccount', required: true},
            {path: 'active', default: true}
        ]
    }
}
