import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from "lodash"
import { HashLink as Link } from 'react-router-hash-link'
import PhoneInput from "react-phone-input-2"
import './Registration.css'
import DynamicStyledInput from "./Form/DynamicStyledInput"
import Files from "./Files"
import {colourStyles} from "../apps/KpModule/components/Filter/SelectStyles"
import {Select} from "./Form"
import CheckBoxField from "./Form/CheckboxField"
import SpinningDots from "./Loader/SpinningDots"
import logoCNDA from  '../img/logo_cnda.svg'
import ValidationUI from "./ValidationUI"

const removeElemAtIndex = (array, index) => {
    if(array.length <= index ) return array
    const arrayOfLetters = ['a', 'b', 'c', 'd', 'e', 'f'];

    const halfBeforeTheUnwantedElement = arrayOfLetters.slice(0, index)

    const halfAfterTheUnwantedElement = array(index + 1);

    return halfBeforeTheUnwantedElement.concat(halfAfterTheUnwantedElement);

}

const arrayToObject = (array= []) =>
    array.reduce((obj, item) => {
        obj[item.id] = item
        return obj
    }, {})

const FormContainer = (props) => {
    return (
        <div className="Registration-container">
            <div className="Registration-form-container">
                <div className= "Registration-well">
                    {props.children}
                </div>
            </div>
        </div>
    )
}


class Registration extends Component {
    constructor(props) {
        super(props)

        this.state = {
            civility: '',
            firstname: '',
            lastname: '',
            mail: '',
            mobile: '',
            phone: '',
            organizationReferent: false,
            liberalityReferent: false,
            functions: [],
            organizationName: '',
            organizationAddress: '',
            organizationAdditionalAddress: '',
            organizationZipCode: '',
            organizationCity: '',
            organizationCountry: 'FRANCE',
            rup: false,
            endowmentFund: false,
            activities: [],
            hasShelter: false,
            shelterName: '',
            shelterAddress: '',
            shelterAdditionalAddress: '',
            shelterZipCode: '',
            shelterCity: '',
            shelterCountry: 'FRANCE',
            animalTypes: [],
            shelterTypes: [],
            files: [],
            orgStatusesUploaded: false,
            orgLatestReportUploaded: false,
            orgStatusNoticeUploaded: false,
            RIBUploaded: false,
            declarationOfHonor: false,
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleFileUpload = this.handleFileUpload.bind(this)
        this.handleFileDeletion = this.handleFileDeletion.bind(this)
    }

    handleChange(field, value) {
        const result = {
            [field]: value
        }
        this.setState(result)

        this.props.validateField(result)
    }

    handleFileUpload(filesObject) {
        this.setState({
            files: [
                ...this.state.files,
                filesObject
            ],

        })
    }

    handleFileDeletion(index) {
        this.setState({
            files: removeElemAtIndex(this.state.files, index)
        })
    }

    handleSubmit(event) {
        event.preventDefault()

        const { handleSubmit } = this.props
        handleSubmit && handleSubmit(this.state)
    }

    getSelectComponent(path, optionsPath, isMulti, removeLabel) {
        const {t} = this.props
        const options = this.props[optionsPath]
        const optionsById = this.props[`${optionsPath}ById`]

        return <Select
            path={t(path)}
            options={options}
            noOptionsMessage={() => t('noOptions')}
            //className="Form-TagsField"
            isMulti={isMulti}
            styles={colourStyles}
            multi={true}
            onChange={optionOrOptions => {
                let selectedValue
                if(isMulti) {
                    selectedValue = optionOrOptions ? optionOrOptions.map(o => o.value) : []
                } else {
                    selectedValue = optionOrOptions ? optionOrOptions.value : ''
                }
                this.handleChange(path, selectedValue)
            }}
            value={
                isMulti
                    ? this.state[path].map(id => optionsById[id])
                    : optionsById[this.state[path]]
            }
            isDisabled={false}
            isClearable={true}
            removeLabel={removeLabel}
            placeholder={t('select')}
            t={t}
        />
    }

    render() {
        const {t, success, loading, validationErrors} = this.props
        const {
            files, firstname, lastname, mail, mobile, phone, organizationReferent,
            liberalityReferent, organizationName, organizationAddress, organizationAdditionalAddress,
            organizationZipCode, organizationCity, organizationCountry, rup, endowmentFund, hasShelter,
            shelterName, shelterAddress, shelterAdditionalAddress, shelterZipCode, shelterCity, shelterCountry,
            orgStatusesUploaded, orgLatestReportUploaded, orgStatusNoticeUploaded, RIBUploaded, declarationOfHonor
        } = this.state

        const filesIds = files && files.map(o => o.id)
        const filesById = arrayToObject(files)

        if(success) {
            return (
                <FormContainer>
                    Vous serez redirigé sous peu ...
                </FormContainer>
            )
        }

        if(loading) {
            return (
                <FormContainer>
                    <SpinningDots/>
                </FormContainer>
            )
        }

        return (
            <FormContainer>
                <img
                    id="logoCNDAo"
                    src={logoCNDA}
                    alt="logoCNDA"
                    style={{height: '150px'}}
                />
                <div className="Registration-from-title">Formulaire d’adhésion</div>
                <div className="Registration-links-container">
                    <Link
                        className="Registration-link"
                        to={`/registration#user`}
                        scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
                    >
                        Mes coordonnées
                    </Link>
                    <Link
                        className="Registration-link"
                        to={`/registration#organization`}
                        scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
                    >
                        Notre association
                    </Link>
                    <Link
                        className="Registration-link"
                        to={`/registration#shelter`}
                        scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
                    >
                        Notre Refuge / Fourrière
                    </Link>
                </div>
                <form onSubmit={this.handleSubmit} className="Registration-Form">
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <div id="user" className="section-title">Mes coordonnées</div>

                        <div className="col-sm-12 col-md-12" style={{padding: '0'}}>
                            {this.getSelectComponent('civility', 'civilities', false, false)}
                        </div>
                        { !!validationErrors['civility'] && <ValidationUI t={t} error={validationErrors['civility']}/> }
                        <br />
                        <DynamicStyledInput
                            id="RegistrationForm-firstname"
                            path="firstname"
                            type="text"
                            value={firstname}
                            onChange={event => this.handleChange("firstname", event.target.value)}
                            t={t}
                        />
                        { !!validationErrors['firstname'] && <ValidationUI t={t} error={validationErrors['firstname']}/> }
                        <br />
                        <DynamicStyledInput
                            id="RegistrationForm-lastname"
                            path="lastname"
                            type="text"
                            value={lastname}
                            onChange={event => {
                                const uppercased = event.target.value?.toUpperCase()
                                this.handleChange("lastname", uppercased)
                            }}
                            t={t}
                        />
                        { !!validationErrors['lastname'] && <ValidationUI t={t} error={validationErrors['lastname']}/> }
                        <br />
                        <DynamicStyledInput
                            id="RegistrationForm-mail"
                            path="mail"
                            type="text"
                            value={mail}
                            onChange={event => this.handleChange("mail", event.target.value)}
                            t={t}
                        />
                        { !!validationErrors['mail'] && <ValidationUI t={t} error={validationErrors['mail']}/> }
                        <br />
                        <div style={{margin: '0px 5px 10px'}}>Téléphone Mobile</div>
                        <PhoneInput
                            country="fr"
                            localization={'fr'}
                            value={mobile}
                            onChange={value => this.handleChange("mobile", value)}
                            isValid={(inputNumber, country) => _.startsWith(inputNumber, country.dialCode)}
                            onlyCountries={['fr']}
                            specialLabel=""
                            inputClass="PhoneNumberInput-input"
                        />
                        { !!validationErrors['mobile'] && <ValidationUI t={t} error={validationErrors['mobile']}/> }
                        <br />
                        <div style={{margin: '0px 5px 10px'}}>Téléphone Fixe</div>
                        <PhoneInput
                            country="fr"
                            localization="fr"
                            value={phone}
                            onChange={value => this.handleChange("phone", value)}
                            isValid={(inputNumber, country) => _.startsWith(inputNumber, country.dialCode)}
                            onlyCountries={['fr']}
                            specialLabel=""
                            inputClass="PhoneNumberInput-input"
                        />
                        { !!validationErrors['phone'] && <ValidationUI t={t} error={validationErrors['phone']}/> }
                        <br />
                        <CheckBoxField
                            label={"Je suis référent de l’association"}
                            value={organizationReferent}
                            onChange={value => this.handleChange("organizationReferent", value)}
                            readOnly={false}
                            disabled={false}
                            t={t}
                        />
                        <br />
                        <CheckBoxField
                            label={"Je suis référent libéralités de l’association"}
                            value={liberalityReferent}
                            onChange={value => this.handleChange("liberalityReferent", value)}
                            readOnly={false}
                            disabled={false}
                            t={t}
                        />
                        <br/>
                        <div style={{margin: '0px 5px 10px'}}>J’interviens au sein de l’association en tant que</div>
                        {this.getSelectComponent('functions', 'functions', true, true)}
                        { !!validationErrors['functions'] && <ValidationUI t={t} error={validationErrors['functions']}/> }
                        <br />
                        <div id="organization" className="section-title">Notre association</div>
                        <DynamicStyledInput
                            id="RegistrationForm-organization"
                            path="name"
                            type="text"
                            value={organizationName}
                            onChange={event => this.handleChange("organizationName", event.target.value)}
                            t={t}
                        />
                        { !!validationErrors['organizationName'] && <ValidationUI t={t} error={validationErrors['organizationName']}/> }
                        <br />
                        <DynamicStyledInput
                            id="RegistrationForm-organization"
                            path="address"
                            type="text"
                            value={organizationAddress}
                            onChange={event => this.handleChange("organizationAddress", event.target.value)}
                            t={t}
                        />
                        { !!validationErrors['organizationAddress'] && <ValidationUI t={t} error={validationErrors['organizationAddress']}/> }
                        <br />
                        <DynamicStyledInput
                            id="RegistrationForm-organization"
                            path="additionalAddress"
                            type="text"
                            value={organizationAdditionalAddress}
                            onChange={event => this.handleChange("organizationAdditionalAddress", event.target.value)}
                            t={t}
                        />
                        <br />
                        <DynamicStyledInput
                            id="RegistrationForm-organization"
                            path="zipCode"
                            type="text"
                            value={organizationZipCode}
                            onChange={event => this.handleChange("organizationZipCode", event.target.value)}
                            t={t}
                        />
                        { !!validationErrors['organizationZipCode'] && <ValidationUI t={t} error={validationErrors['organizationZipCode']}/> }
                        <br />
                        <DynamicStyledInput
                            id="RegistrationForm-organization"
                            path="city"
                            type="text"
                            value={organizationCity}
                            onChange={event => {
                                const uppercased = event.target.value?.toUpperCase()
                                this.handleChange("organizationCity", uppercased)
                            }}
                            t={t}
                        />
                        { !!validationErrors['organizationCity'] && <ValidationUI t={t} error={validationErrors['organizationCity']}/> }
                        <br />
                        <DynamicStyledInput
                            id="RegistrationForm-organization"
                            path="country"
                            type="text"
                            value={organizationCountry}
                            onChange={event => {
                                const uppercased = event.target.value?.toUpperCase()
                                this.handleChange("organizationCountry", uppercased)
                            }}
                            t={t}
                        />
                        { !!validationErrors['organizationCountry'] && <ValidationUI t={t} error={validationErrors['organizationCountry']}/> }
                        <br />
                        <CheckBoxField
                            label={"Notre association est RUP"}
                            value={rup}
                            onChange={value => this.handleChange("rup", value)}
                            readOnly={false}
                            disabled={false}
                            t={t}
                        />
                        <br />
                        <CheckBoxField
                            label={"Notre association dispose d’un fonds de dotations"}
                            value={endowmentFund}
                            onChange={value => this.handleChange("endowmentFund", value)}
                            readOnly={false}
                            disabled={false}
                            t={t}
                        />
                        <br/>
                        <div style={{margin: '0px 5px 10px'}}>Activité(s) de notre association</div>
                        {this.getSelectComponent('activities', 'activities', true, true)}
                        { !!validationErrors['activities'] && <ValidationUI t={t} error={validationErrors['activities']}/> }
                        <br/>
                        <div id="shelter" className="section-title">Notre Refuge / Fourrière</div>
                        <br/>
                        <CheckBoxField
                            label={"Nous disposons d’un refuge"}
                            value={hasShelter}
                            onChange={value => this.handleChange("hasShelter", value)}
                            readOnly={false}
                            disabled={false}
                            t={t}
                        />
                        <br/>
                        {
                            this.state.hasShelter && (
                                <>
                                    <DynamicStyledInput
                                        id="RegistrationForm-organization"
                                        path="name"
                                        type="text"
                                        value={shelterName}
                                        onChange={event => {
                                            const uppercased = event.target.value?.toUpperCase()
                                            this.handleChange("shelterName", uppercased)
                                        }}
                                        t={t}
                                    />
                                    { !!validationErrors['shelterName'] && <ValidationUI t={t} error={validationErrors['shelterName']}/> }
                                    <br />
                                    <DynamicStyledInput
                                        id="RegistrationForm-organization"
                                        path="address"
                                        type="text"
                                        value={shelterAddress}
                                        onChange={event => this.handleChange("shelterAddress", event.target.value)}
                                        t={t}
                                    />
                                    { !!validationErrors['shelterAddress'] && <ValidationUI t={t} error={validationErrors['shelterAddress']}/> }
                                    <br />
                                    <DynamicStyledInput
                                        id="RegistrationForm-organization"
                                        path="additionalAddress"
                                        type="text"
                                        value={shelterAdditionalAddress}
                                        onChange={event => this.handleChange("shelterAdditionalAddress", event.target.value)}
                                        t={t}
                                    />
                                    <br />
                                    <DynamicStyledInput
                                        id="RegistrationForm-organization"
                                        path="zipCode"
                                        type="text"
                                        value={shelterZipCode}
                                        onChange={event => this.handleChange("shelterZipCode", event.target.value)}
                                        t={t}
                                    />
                                    { !!validationErrors['shelterZipCode'] && <ValidationUI t={t} error={validationErrors['shelterZipCode']}/> }
                                    <br />
                                    <DynamicStyledInput
                                        id="RegistrationForm-organization"
                                        path="city"
                                        type="text"
                                        value={shelterCity}
                                        onChange={event => {
                                            const uppercased = event.target.value?.toUpperCase()
                                            this.handleChange("shelterCity", uppercased)
                                        }}
                                        t={t}
                                    />
                                    { !!validationErrors['shelterCity'] && <ValidationUI t={t} error={validationErrors['shelterCity']}/> }
                                    <br />
                                    <DynamicStyledInput
                                        id="RegistrationForm-organization"
                                        path="country"
                                        type="text"
                                        value={shelterCountry}
                                        onChange={event => {
                                            const uppercased = event.target.value?.toUpperCase()
                                            this.handleChange("shelterCountry", uppercased)
                                        }}
                                        t={t}
                                    />
                                    { !!validationErrors['shelterCountry'] && <ValidationUI t={t} error={validationErrors['shelterCountry']}/> }
                                    <br/>
                                    <div style={{margin: '0px 5px 10px'}}>Notre refuge / fourrière prend en charge les animaux</div>
                                    {this.getSelectComponent('animalTypes', 'animalTypes', true, true)}
                                    { !!validationErrors['animalTypes'] && <ValidationUI t={t} error={validationErrors['animalTypes']}/> }
                                    <br/>
                                    <div style={{margin: '0px 5px 10px'}}>Activité(s) de notre refuge / fourrière</div>
                                    {this.getSelectComponent('shelterTypes', 'shelterTypes', true, true)}
                                    { !!validationErrors['shelterTypes'] && <ValidationUI t={t} error={validationErrors['shelterTypes']}/> }
                                    <br/>
                                </>
                            )
                        }
                        <br/>
                        <br/>
                        <div style={{margin: '0px 5px 10px'}}>Documents</div>
                        <Files
                            url="publicFile"
                            anonymous={true}
                            allIds={filesIds}
                            byId={filesById}
                            push={this.handleFileUpload}
                            remove={this.handleFileDeletion}
                            editable={true}
                            t={t}
                        />
                        <br/>
                        <CheckBoxField
                            label={"J’ai téléchargé les statuts de mon association"}
                            value={orgStatusesUploaded}
                            onChange={value => this.handleChange("orgStatusesUploaded", value)}
                            readOnly={false}
                            disabled={false}
                            t={t}
                        />
                        <br/>
                        <CheckBoxField
                            label={"J’ai téléchargé le dernier bilan de mon association"}
                            value={orgLatestReportUploaded}
                            onChange={value => this.handleChange("orgLatestReportUploaded", value)}
                            readOnly={false}
                            disabled={false}
                            t={t}
                        />
                        <br/>
                        <CheckBoxField
                            label={"J’ai téléchargé l’avis de situation de mon association"}
                            value={orgStatusNoticeUploaded}
                            onChange={value => this.handleChange("orgStatusNoticeUploaded", value)}
                            readOnly={false}
                            disabled={false}
                            t={t}
                        />
                        <br/>
                        <CheckBoxField
                            label={"J’ai téléchargé le Relevé d’Identité Bancaire de mon association"}
                            value={RIBUploaded}
                            onChange={value => this.handleChange("RIBUploaded", value)}
                            readOnly={false}
                            disabled={false}
                            t={t}
                        />
                        <br/>
                        <br/>
                        <CheckBoxField
                            label={"Je certifie sur l’honneur l’exactitude des informations fournies"}
                            value={declarationOfHonor}
                            onChange={value => this.handleChange("declarationOfHonor", value)}
                            readOnly={false}
                            disabled={false}
                            t={t}
                        />
                        <br/>
                        <br/>
                        <button type="submit" className="btn Registration-mainButton remove-button-outline" value="submit">
                            Soumettre ma demande d’adhésion
                        </button>
                    </div>
                </form>
            </FormContainer>
        )
    }
}

Registration.propTypes = {
    handleSubmit: PropTypes.func.isRequired
}

export default Registration

