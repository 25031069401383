export const entity = {
    name: 'NatureOfHelp',
    facets: ['codeName']
}


export const module_ = {
    object: 'NatureOfHelp',
    tKey: 'mTitle_natureOfHelp',
    category: {
        path: 'configuration',
        icon: 'settings'
    },
    viewMap: {
        dt: [
            {path: 'code'},
            {path: 'name'},
        ],
        form: [
            {path: 'code'},
            {path: 'name'}
        ]
    }
}
