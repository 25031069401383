const _ = require('lodash')
const crypto = require('crypto')
const {fieldPathJoinGetter} = require('../../utils/kp3Utils')
const { sendMail } = require('./mails/userInitialization')
const {validateButton, saveButton, returnButton} = require('./utils')
const Errors = require('../../utils/Errors').default
const {basicContext} = require('../../utils/contextUtils')
const { generateFetchFieldListAction } = require('../../../apps/KpModule/actions/api')
const {setFieldVisibility} = require('../../../apps/KpModule/actions')

export const entities = [
    {
        name: 'CUser',
        facets: ['comments', 'files'],
        fields: [
            {type: 'Civility', nullable: true},
            {path: 'firstname', nullable: true},
            {path: 'lastname', nullable: true},
            'Language',
            {path: 'phone', encrypted: true},
            {path: 'phone2', encrypted: true},
            {path: 'phone3', encrypted: true},
            {path: 'kpUser', type: 'User'},
            {path: 'active', type: 'boolean', nullable: true},
            {path: 'mail', unique: true, encrypted: true},
            {type: 'Organization', nullable: true},
            {path: 'organizationReferent', type: 'boolean'},
            {path: 'liberalityReferent', type: 'boolean'},
            {type: 'Function', link: "MTM", nullable: true},
            {type: 'Shelter', link: "MTM", nullable: true},
            {type: 'AnimalType', link: 'MTM', nullable: true},
            {path: 'authorizations', type: 'Profile', link: 'MTM', nullable: true},
            'status',
            { path: 'lastUser', type: 'User', nullable: true },
            { path: 'lastModificationDate', type: 'date', nullable: true },
            fieldPathJoinGetter({
                path: "fullName",
                fieldPath: ["firstname", "lastname"],
                joinString: " "
            }),
            {
                path: "noDeleteButtonAccess",
                f: function () {
                    return this.status !== 'waiting'
                }
            },
            {
                path: 'buttons',
                $f: function (user, context, callback) {
                    if(context.clientContext.moduleId === 'm-C-information') {
                        return callback(null, [saveButton, returnButton])
                    }

                    if(context.clientContext.moduleId === 'm-C-users') {
                        if(user.status === 'waiting') return callback(null, [validateButton, saveButton, returnButton])
                    }

                    return callback(null, [saveButton, returnButton])
                }
            }
        ],
        filters: [
            {
                name: "byUser",
                isDefault: false,
                query: function(context) {
                    const user = context.user
                    if(user) return {kpUser: new global.ObjectID(user.id)}
                }
            },
            {
                name: "byOrganization",
                isDefault: false,
                async: true,
                query: function(context, callback) {
                    const user = context.user
                    global.app.C.CUser.get({kpUser: global.ObjectID(user.id)}, {
                        ...basicContext(context),
                        fieldPath: ['id', 'organization.id']
                    }, (error, cUser) => {
                        if(error) return callback(error)
                        callback(null, {organization: global.ObjectID(cUser.organization.id)})
                    })
                }
            }
        ],
        beforeSave: async function (newObject, oldObject, context, callback) {

            newObject.lastUser = new global.ObjectID(context.user.id)
            newObject.lastModificationDate = new Date()

            const action  = context.restAction && context.restAction.crudType
            const profiles = newObject.authorizations
                ? newObject.authorizations.map(auth => new global.ObjectID(auth.id))
                : []

            const currentGroup = new global.ObjectID(context.group.id)
            const currentGroupModel = new global.ObjectID(context.groupModel.id)

            const groups = [
                {
                    group: currentGroup,
                    super_: false,
                    groupModels: [
                        {
                            groupModel: currentGroupModel,
                            super_: false,
                            profiles
                        }
                    ]
                }
            ]

            if(action === 'C') {
                newObject.status = 'waiting'
                if(context.clientContext.moduleId === 'm-C-information') {
                    const cUser = await global.app.C.CUser.get({kpUser: global.ObjectID(context.user.id)}, {
                        ...basicContext(context),
                        fieldPath: ['id', 'organization.id']
                    })

                    if(!cUser) return callback(new Error('permissionToCreateUserDenied'))
                    newObject.organization = _.clone(cUser.organization)
                }
            }


            if(newObject.status === 'waiting' && context.action === 'validate') {
                return crypto.randomBytes(20, (err, buf) => {
                    if(err) callback(err)

                    const token = buf.toString('hex')

                    const query = {mail: newObject.mail}
                    const userObject = {
                        ..._.pick(newObject, ['civility', 'firstname', 'lastname', 'mail', 'phone', 'active']),
                        language: _.get(newObject, 'language.id'),
                        resetPasswordToken: token,
                        resetPasswordExpires: Date.now() + 86400000,
                        groups
                    }

                    return global.User.collection.findOneAndUpdate(query, {$set: userObject}, {upsert: true}, (e, result) => {
                        if(e) return callback(e)
                        const {lastErrorObject, value} = result

                        const id = lastErrorObject.upserted ? lastErrorObject.upserted : value._id
                        newObject.kpUser = {id}

                        sendMail(token, userObject, context, (error, msg) => {console.log(msg)})

                        newObject.status = 'validated'

                        return callback(null, newObject, oldObject)

                    })
                })
            }

            if(newObject.status === 'waiting') return callback(null, newObject, oldObject)
            if(newObject.status === 'validated') {
                const difference = newObject.authorizations.some(auth => !oldObject.authorizations.find(oldAuth => oldAuth.id === auth.id))
                const difference2 = oldObject.authorizations.some(auth => !newObject.authorizations.find(newAuth => newAuth.id === auth.id))
                const profilesChanged = difference || difference2

                const profilesQuery = profilesChanged
                    ? {'groups.$[elem1].groupModels.$[elem2].profiles': profiles}
                    : {}

                const filterProfilesQuery = profilesChanged
                    ? { arrayFilters: [ { "elem1.group": currentGroup } , { "elem2.groupModel": currentGroupModel }]}
                    : {}

                const userObject = {
                    ..._.pick(newObject, ['civility', 'firstname', 'lastname', 'mail', 'phone', 'active']),
                    language: _.get(newObject, 'language.id'),
                    ...profilesQuery
                }

                if(newObject.mail.toUpperCase() !== oldObject.mail.toUpperCase()) {
                    crypto.randomBytes(20, (err, buf) => {
                        if(err) callback(err)
                        const token = buf.toString('hex')

                        userObject.resetPasswordToken = token
                        userObject.resetPasswordExpires = Date.now() + 86400000

                        global.User.collection.updateOne(
                            { _id: new global.ObjectID(oldObject.kpUser) },
                            { $set: userObject },
                            filterProfilesQuery,
                            e => {
                                if(e) return callback(e)
                                sendMail(token, userObject, context, (error, msg) => {console.log(msg)})
                                callback(null, newObject, oldObject)
                            }
                        )
                    })
                } else {
                    global.User.collection.updateOne(
                        {_id: new global.ObjectID(oldObject.kpUser)},
                        { $set: userObject },
                        filterProfilesQuery,
                        e => {
                            if(e) return callback(e)
                            callback(null, newObject, oldObject)
                        }
                    )
                }
            } else callback(new Errors.ValidationError('Statut utilisateur inéxistant'))
        }
    }
]

export const administratorModule = {
    name: 'Users',
    object: 'CUser',
    tKey: 'mTitle_user',
    objectIdentifier: 'mail',
    defaultSortBy: 'lastname',
    defaultSortDirection: 'ASC',
    protectedExport: true,
    category: {
        path: 'phoneBook',
        icon: 'clipboard'
    },
    viewMap: {
        dt: [
            {path: 'civility', initiallyNotVisible: true},
            'lastname',
            'firstname',
            'mail',
            {path: 'phone', tKey: 'mobile', initiallyNotVisible: true},
            {path: 'phone2', initiallyNotVisible: true},
            {path: 'phone3', initiallyNotVisible: true},
            {path: 'language', tKey: 'correspondenceLanguage', initiallyNotVisible: true},
            'organization',
            {path: 'shelters'},
            {path: 'functions'},
            {path: 'authorizations', tKey: 'profiles', translateName: true, initiallyNotVisible: true},
            {path: 'status', translate: true},
            {path: 'active', width: 100},
            {path: 'lastUser'},
            {path: 'lastModificationDate'},
            {path: 'noDeleteButtonAccess', hidden: true}
        ],
        form: {
            fields: [
                {path: 'civility', required: true, sortList: false},
                {path: 'lastname', uppercase: true},
                {path: 'firstname'},
                {path: 'mail', required: true},
                {path: 'phone', tKey: 'mobile', type: 'phoneNumber'},
                {path: 'phone2', type: 'phoneNumber'},
                {path: 'phone3', type: 'phoneNumber'},
                {path: 'language', tKey: 'correspondenceLanguage', required: true},
                {
                    path: 'organization',
                    display: 'fullName',
                    required: true,
                    fieldPath: ['id', 'fullName', 'organizationType.memberAssociation'],
                    subscriptions: {
                        onChange: (newValue, oldValue, {module, store}) => {
                            console.log('organization change', newValue)
                            store.dispatch(generateFetchFieldListAction(
                                'm-C-users.CUser_shelters',
                                store.getState,
                                'form',
                                {
                                    data: {
                                        organizationId: newValue?.id
                                    }
                                }

                            ))

                            const sheltersField = module.viewMap.form.fields.find(field => field.path === 'shelters')
                            const animalTypesField = module.viewMap.form.fields.find(field => field.path === 'animalTypes')
                            const isMemberAssociation = _.get(newValue, 'organizationType.memberAssociation')


                            if(!isMemberAssociation) {
                                store.dispatch(setFieldVisibility('e_animalTypes', false))
                                sheltersField.setValue([])
                                animalTypesField.setValue([])
                            }

                            const shelters = sheltersField.getValue()
                            store.dispatch(setFieldVisibility(sheltersField.id, !!isMemberAssociation))
                            store.dispatch(setFieldVisibility('e_organizationReferent', !!isMemberAssociation))
                            store.dispatch(setFieldVisibility('e_liberalityReferent', !!isMemberAssociation))
                            store.dispatch(setFieldVisibility(sheltersField.id, !!isMemberAssociation))
                            store.dispatch(setFieldVisibility('e_animalTypes', !!isMemberAssociation && !shelters.length))

                        }
                    }
                },
                {path: 'organizationReferent'},
                {path: 'liberalityReferent'},
                {
                    path: 'shelters',
                    fieldPath: ['id', 'name', 'animalTypes.id'],
                    filters: ['byOrganization'],
                    subscriptions: {
                        onChange: (newValue, oldValue, {module, store}) => {
                            store.dispatch(generateFetchFieldListAction(
                                'm-C-users.CUser_animalTypes',
                                store.getState,
                                'form',
                                {
                                    data: {
                                        shelters: newValue || []
                                    }
                                }

                            ))
                            const animalTypesField = module.viewMap.form.fields.find(field => field.path === 'animalTypes')

                            store.dispatch(setFieldVisibility(animalTypesField.id, !!newValue?.length))
                            if(!newValue) animalTypesField.setValue([])
                        }
                    }
                },
                {path: 'animalTypes', tKey: 'animalManager', filters: ['byShelters']},
                {path: 'functions'},
                {path: 'authorizations', tKey: 'profiles', translateName: true, required: true, filters: ['thisModel']},
                {path: 'comments', textWidth: 1000},
                {path: 'active', default: true},
                {path: 'buttons', hidden: true},
                {path: 'status', hidden: true},
            ]
        }
    }
}

export const userModule = {
    name: 'Information',
    object: 'CUser',
    tKey: 'mTitle_information',
    objectIdentifier: 'mail',
    defaultSortBy: 'lastname',
    defaultSortDirection: 'ASC',
    protectedExport: true,
    category: {
        path: 'myBinder',
        icon: 'folder'
    },
    viewMap: {
        dt: [
            {path: 'civility', initiallyNotVisible: true},
            'lastname',
            'firstname',
            'mail',
            {path: 'phone', tKey: 'mobile', initiallyNotVisible: true},
            {path: 'phone2', initiallyNotVisible: true},
            {path: 'phone3', initiallyNotVisible: true},
            {path: 'language', tKey: 'correspondenceLanguage', required: true},
            'organization',
            {path: 'functions'},
            {path: 'status', translate: true},
            {path: 'active', width: 100},
            {path: 'noDeleteButtonAccess', hidden: true}
        ],
        form: [
            {path: 'civility', required: true, sortList: false},
            {path: 'lastname', uppercase: true},
            {path: 'firstname'},
            {path: 'mail', required: true},
            {path: 'phone', tKey: 'mobile', type: 'phoneNumber'},
            {path: 'phone2', type: 'phoneNumber'},
            {path: 'phone3', type: 'phoneNumber'},
            {path: 'language', tKey: 'correspondenceLanguage', required: true},
            {
                path: 'shelters',
                fieldPath: ['id', 'name', 'animalTypes.id'],
                filters: ['byUserOrganization'],
                subscriptions: {
                    onChange: (newValue, oldValue, {module, store}) => {
                        store.dispatch(generateFetchFieldListAction(
                            'm-C-information.CUser_animalTypes',
                            store.getState,
                            'form',
                            {
                                data: {
                                    shelters: newValue || []
                                }
                            }

                        ))
                        const animalTypesField = module.viewMap.form.fields.find(field => field.path === 'animalTypes')

                        store.dispatch(setFieldVisibility(animalTypesField.id, !!newValue?.length))
                        if(!newValue) animalTypesField.setValue([])
                    }
                }
            },
            {path: 'animalTypes', tKey: 'animalManager', filters: ['byShelters']},
            {path: 'functions', filters: ['eligibleToAssociation']},
            {path: 'organization', hidden: true},
            {path: 'authorizations', hidden: true},
            {path: 'status', hidden: true},
            {path: 'buttons', hidden: true},
        ]
    },
    filters: [
        'byOrganization'
    ]
}


export const profileModule = {
    name: 'Profile',
    object: 'CUser',
    tKey: 'mTitle_profile',
    defaultPanel: 'form',
    objectIdentifier: 'mail',
    defaultSortBy: 'lastname',
    defaultSortDirection: 'ASC',
    protectedExport: true,
    newable: false,
    removable: false,
    category: {
        path: 'myBinder',
        icon: 'folder'
    },
    viewMap: {
        dt: [
            {path: 'civility', initiallyNotVisible: true},
            'lastname',
            'firstname',
            'mail',
            {path: 'phone', tKey: 'mobile', initiallyNotVisible: true},
            {path: 'phone2', initiallyNotVisible: true},
            {path: 'phone3', initiallyNotVisible: true},
            {path: 'language', tKey: 'correspondenceLanguage', required: true},
            'organization',
            {path: 'functions'},
        ],
        form: [
            {path: 'civility', required: true, sortList: false},
            {path: 'lastname', uppercase: true},
            {path: 'firstname'},
            {path: 'mail', required: true},
            {path: 'phone', tKey: 'mobile', type: 'phoneNumber'},
            {path: 'phone2', type: 'phoneNumber'},
            {path: 'phone3', type: 'phoneNumber'},
            {path: 'language', tKey: 'correspondenceLanguage', required: true},
            {
                path: 'shelters',
                fieldPath: ['id', 'name', 'animalTypes.id'],
                filters: ['byUserOrganization'],
                subscriptions: {
                    onChange: (newValue, oldValue, {module, store}) => {
                        store.dispatch(generateFetchFieldListAction(
                            'm-C-profile.CUser_animalTypes',
                            store.getState,
                            'form',
                            {
                                data: {
                                    shelters: newValue || []
                                }
                            }

                        ))
                        const animalTypesField = module.viewMap.form.fields.find(field => field.path === 'animalTypes')

                        store.dispatch(setFieldVisibility(animalTypesField.id, !!newValue?.length))
                        if(!newValue) animalTypesField.setValue([])
                    }
                }
            },
            {path: 'animalTypes', tKey: 'animalManager', filters: ['byShelters']},
            {path: 'functions', filters: ['eligibleToAssociation']},
            {path: 'authorizations', hidden: true},
            {path: 'status', hidden: true},
            {path: 'buttons', hidden: true},
        ]
    },
    filters: [
        'byUser'
    ]
}
