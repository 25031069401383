import React from 'react'
import {AlertCircle} from 'react-feather'

const ValidationUI = (props) => {
    const translate = props.t
        ? props.t
        : x => x
    return <span style={{color: 'rgb(212 2 2 / 90%)'}}><AlertCircle size={14} style={{marginTop: '4px'}}/> {translate(props.error)}</span>
}

export default ValidationUI
