import {profileModule} from "./cnda/cuser";

const _ = require('lodash')

export default {
    id: 'C',
    name: 'CNDA',
    description: '',
    languages: ['fr', 'en'],
    entities: _.concat(
        [
            require('./cnda/exercise').entity,
            require('./cnda/zone').entity,
            require('./cnda/function').entity,
            require('./cnda/organizationType').entity,
            require('./cnda/shelter').entity,
            require('./cnda/animalType').entity,
            require('./cnda/bank').entity,
            require('./cnda/kp/accountsGroup').entity,
            require('./cnda/kp/analyticalCode').entity,
            require('./cnda/flow/accountingEntries').entity,
            require('./cnda/flow/bankFlow').entity,
            require('./cnda/batch/batch').entity,
            require('./cnda/entriesExtraction').entity,
            require('./cnda/natureOfHelp').entity,
            require('./cnda/userRegistration').entity
        ],
        require('./cnda/staticEntities').dependencies,
        require('./cnda/organization').entities,
        require('./cnda/contactsModules').entities,
        require('./cnda/kp/accountsTemplate').entities,
        require('./cnda/invoice/registration').entities,
        require('./cnda/cuser').entities,
        require('./cnda/capacity').entities,
        require('./cnda/reliefFund/reliefFund').entities
    ),
    modules: _.concat(
        [
            // Configuration
            require('./cnda/exercise').module_,
            require('./cnda/zone').module_,
            require('./cnda/organizationType').module_,
            require('./cnda/animalType').module_,
            require('./cnda/function').module_,
            require('./cnda/bank').module_,
            require('./cnda/natureOfHelp').module_,
            // Annuaire
            require('./cnda/organization').module_,
            require('./cnda/shelter').module_,
            require('./cnda/cuser').administratorModule,
            require('./cnda/capacity').administratorModule,
            require('./cnda/contactsModules').administratorModule,
            require('./cnda/userRegistration').module_,
            // Mon dossier
            require('./cnda/cuser').profileModule,
            require('./cnda/cuser').userModule,
            require('./cnda/organization').userModule,
            require('./cnda/shelter').userModule_,
            require('./cnda/capacity').userModule
        ],
        [
            require('./cnda/contactsModules').userModule,
        ],
        [
            require('./cnda/reliefFund/modules/submission').module_,
            require('./cnda/reliefFund/modules/study').module_,
            require('./cnda/reliefFund/modules/decision').module_,
            require('./cnda/reliefFund/modules/payment').module_,
            require('./cnda/reliefFund/modules/followUp').module_,
        ],
        [
            // Facture
            require('./cnda/invoice/registration').module_,
            require('./cnda/invoice/control').module_,
            require('./cnda/invoice/validation').module_,
            require('./cnda/invoice/invoiceFollowUp').module_,
            // Bank
            require('./cnda/batch/generation').module_,
            require('./cnda/batch/control').module_,
            require('./cnda/batch/payment').module_,
            require('./cnda/flow/bankFlow').module_,
            // Accounting
            require('./cnda/kp/accountsGroup').module_,
            require('./cnda/kp/analyticalCode').module_,
            require('./cnda/kp/accountsTemplate').module_,
            require('./cnda/flow/accountingEntries').module_,
            require('./cnda/entriesExtraction').module_,
            //KP
            require('./cnda/invoice/invoiceKpModule').module_
        ]
    )
}
