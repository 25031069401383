import React from 'react'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import { OBJECT_FORM } from './Form'
import { touch } from 'redux-form'
import VisualComponent from '../../../../components/DtObjectsComponent'
import {getFieldListFields, getGroupModel, getLanguage, getModule} from '../../selectors'
import getFormValidations from './formValidations'
import {exportTableObject} from "../../actions/api";
import {setFilterMemory} from "../../actions";

class FormDtObjectsField extends React.Component {
    constructor(props){
        super(props)
        this.validations = getFormValidations(props.field, props.t, props.listFields)
    }
    render() {
        const {field, listFields, module, t, touch, exportTableObject, language, groupModel, setFilterMemory, objectMode} = this.props
        const disabled = field.disabled
        const editable = !field.editable ? objectMode === 'newObject' : field.editable
        return (
            <Field
                name={field.path}
                path={field.tKey || field.path}
                required={field.required}
                displayTooltip={field.displayTooltip}
                exportTableObject={exportTableObject}
                component={VisualComponent}
                validate={this.validations}
                disabled={disabled || !editable}
                validateOnChange={true}
                listFields={listFields}
                field={field}
                module={module}
                t={t}
                language={language}
                touch={touch}
                groupModel={groupModel}
                setFilterMemory={setFilterMemory}
            />
        )
    }
}

const mapStateToProps = (state, { field }) => ({
    module: getModule(state),
    groupModel: getGroupModel(state),
    listFields: getFieldListFields(state, field.id),
    language: getLanguage(state)
})

const mapDispatchToProps = (dispatch, {user, groupModel, language}) => ({
    exportTableObject: (filename, columns, object) =>
        dispatch(exportTableObject(filename, columns, object, user, false, language, groupModel)),
    touch: field =>
        dispatch(touch(OBJECT_FORM, field)),
    setFilterMemory: query => dispatch(setFilterMemory(query))
})


export default connect(mapStateToProps, mapDispatchToProps)(FormDtObjectsField)
