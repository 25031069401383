import {setFieldVisibility} from "../../../apps/KpModule/actions"

export const entity = {
    name: 'UserRegistration',
    facets: ['files'],
    fields: [
        {path: 'mail', encrypted: true, unique: true},
        {path: 'civility', type: 'Civility'},
        {path: 'firstname'},
        {path: 'lastname'},
        {path: 'mobile'},
        {path: 'phone'},
        {path: 'organizationReferent', type: 'boolean'},
        {path: 'liberalityReferent', type: 'boolean'},
        {path: 'functions', type: 'Function', link: 'MTM'},
        {path: 'organizationName'},
        {path: 'organizationAddress'},
        {path: 'organizationAdditionalAddress'},
        {path: 'organizationZipCode'},
        {path: 'organizationCity'},
        {path: 'organizationCountry'},
        {path: 'rup', type: 'boolean'},
        {path: 'endowmentFund', type: 'boolean'},
        {path: 'activities', type: 'OrganizationActivity', link: 'MTM'},
        {path: 'hasShelter', type: 'boolean'},
        {path: 'shelterName'},
        {path: 'shelterAddress'},
        {path: 'shelterAdditionalAddress'},
        {path: 'shelterZipCode'},
        {path: 'shelterCity'},
        {path: 'shelterCountry'},
        {path: 'animalTypes', type: 'AnimalType', link: 'MTM'},
        {path: 'shelterTypes', type: 'ShelterType', link: 'MTM'},
        {path: 'orgStatusesUploaded', type: 'boolean'},
        {path: 'orgLatestReportUploaded', type: 'boolean'},
        {path: 'orgStatusNoticeUploaded', type: 'boolean'},
        {path: 'RIBUploaded', type: 'boolean'},
        {path: 'declarationOfHonor', type: 'boolean'},
        {path: 'status', type: 'RegistrationStatus'},
        {path: 'createdAt', type: 'date'},
    ]
}

export const module_ = {
    object: 'UserRegistration',
    tKey: 'mTitle_userRegistration',
    objectIdentifier: 'mail',
    category: {
        path: 'phoneBook',
        icon: 'clipboard'
    },
    viewMap: {
        dt: [
            {path: 'mail'},
            {path: 'firstname'},
            {path: 'lastname'},
            {path: 'mobile'},
            {path: 'organization'},
            {path: 'status'},
            {path: 'createdAt', tKey: 'creationDate'},
        ],
        form: {
            fields: [
                {path: 'userSection', data: 'Coordonnées', type: 'heading', hideLabel: true, style: {margin: '15px 5px 0', fontSize: 'larger', color: 'rgb(252, 185, 0)', padding: '10px 0'}},
                {path: 'mail'},
                {path: 'civility'},
                {path: 'firstname'},
                {path: 'lastname'},
                {path: 'mobile', type: 'phoneNumber'},
                {path: 'phone', type: 'phoneNumber'},
                {path: 'organizationReferent'},
                {path: 'liberalityReferent'},
                {path: 'functions'},
                {path: 'organizationSection', data: 'Association', type: 'heading', hideLabel: true, style: {margin: '15px 5px 0', fontSize: 'larger', color: 'rgb(252, 185, 0)', padding: '10px 0'}},
                {path: 'organizationName', tKey: 'name'},
                {path: 'organizationAddress', tKey: 'address'},
                {path: 'organizationAdditionalAddress', tKey: 'additionalAddress'},
                {path: 'organizationZipCode', tKey: 'zipCode'},
                {path: 'organizationCity', tKey: 'city'},
                {path: 'organizationCountry', tKey: 'country'},
                {path: 'rup'},
                {path: 'endowmentFund'},
                {path: 'activities'},
                {
                    path: 'hasShelter',
                    subscriptions: {
                        onChange: (newValue, oldValue, {store}) => {
                            [
                                'shelterSection',
                                'shelterName', 'shelterAddress', 'shelterAdditionalAddress', 'shelterZipCode',
                                'shelterCity', 'shelterCountry', 'animalTypes', 'shelterTypes'
                            ].forEach(path => store.dispatch(setFieldVisibility(`e_${path}`, !!newValue)))
                        }
                    }
                },
                {path: 'shelterSection', data: 'Refuge / Fourrière', type: 'heading', hideLabel: true, style: {margin: '15px 5px 0', fontSize: 'larger', color: 'rgb(252, 185, 0)', padding: '10px 0'}},
                {path: 'shelterName', tKey: 'name'},
                {path: 'shelterAddress', tKey: 'address'},
                {path: 'shelterAdditionalAddress', tKey: 'additionalAddress'},
                {path: 'shelterZipCode', tKey: 'zipCode'},
                {path: 'shelterCity', tKey: 'city'},
                {path: 'shelterCountry', tKey: 'country'},
                {path: 'animalTypes'},
                {path: 'shelterTypes'},
                {path: 'files', tKey: 'documents'},
                {path: 'orgStatusesUploaded'},
                {path: 'orgLatestReportUploaded'},
                {path: 'orgStatusNoticeUploaded'},
                {path: 'RIBUploaded'},
                {path: 'declarationOfHonor'}
            ],
            onOpen: ({ store }) => {
                const state = store.getState()
                const hasShelter = state.edit.object.data?.hasShelter
                console.log(hasShelter)
                store.dispatch(setFieldVisibility(`e_shelterSection`, !!hasShelter))
                store.dispatch(setFieldVisibility(`e_shelterName`, !!hasShelter))
                store.dispatch(setFieldVisibility(`e_shelterAddress`, !!hasShelter))
                store.dispatch(setFieldVisibility(`e_shelterAdditionalAddress`, !!hasShelter))
                store.dispatch(setFieldVisibility(`e_shelterZipCode`, !!hasShelter))
                store.dispatch(setFieldVisibility(`e_shelterCity`, !!hasShelter))
                store.dispatch(setFieldVisibility(`e_shelterCountry`, !!hasShelter))
                store.dispatch(setFieldVisibility(`e_animalTypes`, !!hasShelter))
                store.dispatch(setFieldVisibility(`e_shelterTypes`, !!hasShelter))
            }
        }
    }
}
