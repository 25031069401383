
export const kpBusinessRevue = {
    name: "kpBusinessRevue",
    object: "BusinessRevue",
    category: {
        path: "keenpoint",
        icon: 'tool'
    },
    removable: false,
    newable: false,
    viewMap: {
        dt: [
            "date",
            {path: "businessProjectCountryAndFullName", tKey: "projet", width: 400},
            {path: "executedTurnOver", tKey: "CA Réalisé (k€)"},
            {path: "projectStep", tKey: "étape"},
            {path: "revised", tKey: "CA Révisé (k€)"},
            {path: "yearN", tKey: "Année (k€)"},
            {path: "probability", tKey: "Probabilité (%)"},
            {path: "yearP", tKey: "AnnéeP (k€)"},
            {path: "yearNPlusOne", tKey: "Année+1 (k€)"},
            "comment"
        ],
        form: [
            "date",
            {path: "businessProjectCountryAndFullName", tKey: "projet", writable: false},
            {path: "executedTurnOver", tKey: "CA Réalisé (k€)"},
            {path: "projectStep", tKey: "étape"},
            {path: "comment", type: "textarea"}
        ]
    },
    filters: []
}
